<template>
  <div class="announcements">
    <div class="page-header">
      <h1 class="page-title">Announcements</h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search announcements"
          v-model="search_query"
        />
        <!-- / Search -->

        <!-- <a-button
          class="btn-rounded"
          icon="phone"
          size="large"
          type="default"
          @click.prevent="$router.push('/call-for-help')"
          >Call For Help</a-button
        > -->
        <a-button
          v-if="canCreateAnnouncements"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          @click.prevent="openCreateAnnouncementModal"
          >New Announcement</a-button
        >
      </div>
    </div>

    <create-announcement-modal
      :visible="createAnnouncementModalVisible"
      @close-modal="closeCreateAnnouncementModal"
    ></create-announcement-modal>

    <!-- List wrapper -->
    <div class="announcements-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No announcements -->
      <a-alert
        v-if="!isLoadingCombined && announcementsToShowOrdered.length == 0"
        type="info"
        message="No announcements to show"
      />
      <!-- / No announcements -->

      <!-- Loaded -->
      <div
        class="announcements-list"
        v-if="!isLoadingCombined && announcementsToShowOrdered.length"
      >
        <a-row :gutter="20" type="flex">
          <a-col
            :span="colSpan"
            v-for="announcement in announcementsToShowOrdered"
            :key="announcement.id"
          >
            <announcement-list-item
              :announcement="announcement"
            ></announcement-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Announcements from "../mixins/Announcements";
import Dates from "../mixins/Dates";
import organisations from "../helpers/organisations";
const _ = require("lodash");
import AnnouncementListItem from "./Announcements/AnnouncementListItem.vue";
import CreateAnnouncementModal from "../components/Announcements/CreateAnnouncementModal.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";

export default {
  name: "Announcements",
  mixins: [Announcements, Dates, RequiresNonHiddenPresences],
  components: { AnnouncementListItem, CreateAnnouncementModal },
  data() {
    return {
      createAnnouncementModalVisible: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  created() {
    // this.loadAnnouncements();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
      canCreateAnnouncements: "canCreateAnnouncements",
    }),

    ...mapGetters("announcements", {
      announcementsToShowOrdered: "announcementsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },
  },
  methods: {
    ...mapActions("missionControl", {
      getOrganisationById: "getOrganisationById",
    }),

    ...mapActions("announcements", {
      loadAnnouncements: "loadAnnouncements",
      setSearchQuery: "setSearchQuery",
    }),

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getAnnouncementUrl(announcement) {
      let organisation = this.getOrganisationById(announcement.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/announcements/" + tenantId + "/" + announcement.id;
    },

    openCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = true;
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.announcement-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 10px;
}
</style>